import React, { useState } from 'react';
import Select from "react-select";
 
function SelectCustom(props) {

	const [selected, setSelected] = useState([]);
	const handleChange = (selectedOption) => {
		props.updateFunction(selectedOption.value);
		setSelected(selectedOption);
	};

	return (
		<Select
			options={props.data}
			isLoading={!props.data}
			closeMenuOnSelect={true}
			placeholder={props.title}
			onChange={handleChange}
			value={selected}
			name="group_period"
		/>
	);
}

export default SelectCustom;