import React, { Component } from 'react';
import CanvasJSReact from '../assets/canvasjs/canvasjs.react';
import env from "react-dotenv";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
 
class BarPlotMultiple extends Component {
	constructor(props) {
		super();
		this.toggleDataSeries = this.toggleDataSeries.bind(this);
		this.state = {
			posts: []
		};
	}
	toggleDataSeries(e){
		if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
		}
		else{
			e.dataSeries.visible = true;
		}
		this.chart.render();
	}

	componentDidMount() {
		this.fetchTrans();
	}

	componentDidUpdate(prevProps) {
		if(
			(prevProps.selectedCountry !== this.props.selectedCountry) 
			|| (prevProps.selectedPeriod !== this.props.selectedPeriod)
			|| (prevProps.operationList !== this.props.operationList)){
			this.fetchTrans();
		}
	}

	fetchTrans(){
		var operations = [];
		this.props.operationList.forEach(element => {
			operations.push(element.value);
		});
		if(operations.length === 0){
			operations.push("CASHIN_ORANGE")
		}
		fetch(env.API_URL+'/api/'+this.props.gate+'/bar/'+this.props.selectedPeriod+'/'+this.props.selectedCountry+'/'+operations.join(","))
			.then((response) => response.json())
			.then((data) => {
				this.setState({posts:data});
			})
			.catch((err) => {
				console.log("BARPLOTMULIPLE : TRANSACTION");
				console.log(err);
		});
	}

	render() {
		const options = {
			animationEnabled: true,
			exportEnabled: true,
			title: {
				text: "OPERATION COMPARAISON : "+ this.props.selectedCountry,
			},
			axisY: {
				title: (this.props.gate === 'user') ? "Nbre" : "in CAD",
				prefix: (this.props.gate === 'user') ? "" : "$",
				suffix: (this.props.gate === 'user') ? "" : "k"
			},
			toolTip: {
				shared: true,
				reversed: true
			},
			legend: {
				verticalAlign: "center",
				horizontalAlign: "right",
				reversed: true,
				cursor: "pointer",
				itemclick: this.toggleDataSeries
			},
			data: this.state.posts
		}

		return (
			<div className="topSpace">
				<CanvasJSChart options = {options}  onRef={ref => this.chart = ref} />
			</div>
		);
	}
}

export default BarPlotMultiple;