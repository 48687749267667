import React, {useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import BarPlotMultiple from '../../component/BarPlotMultiple';
import PieMultiple from '../../component/PieMultiple';
import LineMultiples from '../../component/LineMultiples';
import TransactionFilter from '../../component/TransactionFilter';
import TableData from '../../component/TableData';

function User() {
        const[selectedPeriod, updatePeriod] = useState("MONTLY");
        const[selectedCountry, updateCountry] = useState("CM");
        const[startDate, updateStartDate] = useState('2/1/2020');
        const[endDate, updateEndDate] = useState('5/30/2023');
        const[operationList, updateOperationList] = useState([]);
        return (
            <div>
                <Row>
                    <Container>
                        <TransactionFilter 
                            gate="user"
                            selectedPeriod={selectedPeriod}
                            updatePeriod={updatePeriod}
                            selectedCountry={selectedCountry}
                            updateCountry={updateCountry}
                            startDate={startDate}
                            updateStartDate={updateStartDate}
                            endDate={endDate}
                            updateEndDate={updateEndDate}
                            operationList={operationList}
                            updateOperationList={updateOperationList}
                        />
                    </Container>
                </Row>
                <Row>
                    <Col xl={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }} xs={{ span: 8, offset: 2 }}>
                        <Container>
                            <BarPlotMultiple gate="user" operationList={operationList} selectedCountry={selectedCountry} selectedPeriod={selectedPeriod}/>
                        </Container>
                    </Col>
                    <Col xl={{ span: 2, offset: 0 }} lg={{ span: 2, offset: 0 }} xs={{ span: 2, offset: 0 }}>
                        <Container>
                            <PieMultiple gate="user" operationList={operationList} selectedCountry={selectedCountry} selectedPeriod={selectedPeriod} title="CURRENT YEAR" year="CURRENT"/>
                        </Container>
                    </Col>
                    <Col xl={{ span: 2, offset: 0 }} lg={{ span: 2, offset: 0 }} xs={{ span: 2, offset: 0 }}>
                        <Container>
                            <PieMultiple gate="user" operationList={operationList} selectedCountry={selectedCountry} selectedPeriod={selectedPeriod} title="LAST YEAR" year="LAST"/>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Container>
                        <LineMultiples gate="user" selectedPeriod={selectedPeriod} />
                    </Container>
                </Row>
                <Row>
                    <Container>
                        <TableData gate="user" operationList={operationList} selectedCountry={selectedCountry} selectedPeriod={selectedPeriod} />
                    </Container>
                </Row>
            </div>
        );
}

export default User;