import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

function  MultiSelectCustom(props) {

    const [selected, setSelected] = useState([]);
    const handleChange = (selectedOption) => {
        setSelected(selectedOption);
        props.updateFunction(selectedOption);
    };

    return (
        <MultiSelect
          options={props.data}
          value={selected}
          onChange={handleChange}
          labelledBy={props.title}
        />
    );
  };
  
  export default MultiSelectCustom;