import React from 'react';
import Title from '../component/Title';
import Navigation from '../component/Navigation';

function GeneralPage() {
        return (
            <div>
                <Title />
                <Navigation/>
            </div>
        );
}

export default GeneralPage;