import React, { Component } from 'react';
import {Route, Routes} from "react-router";
import Error from "../pages/Error";
import GeneralPage from '../pages/GeneralPage';

class Template extends Component {
  
  render() {    
    return (
        <Routes>
            <Route path="/" element={<GeneralPage />} />
            <Route path="*" element={<Error />} />
        </Routes>
    );
  }
}

export default Template;