import React, { useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Col from "react-bootstrap/Col";
import Row from 'react-bootstrap/Row';
//import DateRange from './DateRange';
import env from "react-dotenv";
import MultiSelectOperation from './MultiSelectCustom';
import SelectCustom from './SelectCustom';
import countryList from '../assets/data/country.json';
import periodList from '../assets/data/period.json';
 
function TransactionFilter(props) {

		const[listOperationInit, updatelistOperationInit] = useState([]);
		useEffect(() => {
			fetch(env.API_URL+'/api/'+props.gate+'/operation')
				.then((response) => response.json())
				.then((data) => {
					updatelistOperationInit(data);
				})
				.catch((err) => {
					console.log("FILTER : OPARATION : GLOBAL");
					console.log(err);
			});
		},[]);

		return (
			<div className="topSpace_filter">
				<Row>
					{/* <Col xl={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 1 }} xs={{ span: 2, offset: 1  }}>
						<DateRange startDate={props.startDate} endDate={props.endDate} updateStartDate={props.updateStartDate} updateEndDate={props.updateEndDate}/>						
					</Col> */}
					<Col xl={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 1  }} xs={{ span: 2, offset: 1  }}>
						<SelectCustom updateFunction={props.updatePeriod} data={periodList} title="select period"/>
					</Col>
					<Col xl={{ span: 4, offset: 1 }} lg={{ span: 4, offset: 1  }} xs={{ span: 4, offset: 1  }}>
						<MultiSelectOperation updateFunction={props.updateOperationList} title="select operation" data={listOperationInit}/>
					</Col>
					<Col xl={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 1  }} xs={{ span: 2, offset: 1  }}>
						<SelectCustom updateFunction={props.updateCountry} data={countryList} title="select country"/>
					</Col>
				</Row>
			</div>
		);
}

export default TransactionFilter;