import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import env from "react-dotenv";
import DataTableExtensions from 'react-data-table-component-extensions';

class TableData extends Component {
    constructor() {
        super();
        this.state = {
            tableData:{
                columns: [],
                data: []  
            }
        };
    }

    componentDidMount() {
		this.fetchTrans();
	}

	componentDidUpdate(prevProps) {
		if(
            (prevProps.selectedCountry !== this.props.selectedCountry) 
            || (prevProps.selectedPeriod !== this.props.selectedPeriod)
            | (prevProps.operationList !== this.props.operationList)){
			this.fetchTrans();
		}
	}

	fetchTrans(){
        var operations = [];
		this.props.operationList.forEach(element => {
			operations.push(element.value);
		});
		if(operations.length === 0){
			operations.push("CASHIN_ORANGE")
		}
        fetch(env.API_URL+'/api/'+this.props.gate+'/table/'+this.props.selectedPeriod+'/'+this.props.selectedCountry+'/'+operations.join(","))
            .then((response) => response.json())
            .then((data) => {
                this.setState({ tableData: {columns: data.colums, data: data.result} });
            })
            .catch((err) => {
                console.log("TABLEDATA : TRANSACTION");
                this.setState({ tableData: {columns: this.state.tableData.columns, data: []} });
                console.log(err);
        });
	}

    render() {
        return(
            <div className = "topSpace padingSpace" >
                <DataTableExtensions {...this.state.tableData}>
                    <DataTable
                        title={"ALL Transaction : "+this.props.selectedCountry}
                        export="true"
                        columns={this.state.tableData.columns}
                        data={this.state.tableData.data}
                        pagination
                        highlightOnHover
                    />
                </DataTableExtensions>
            </div>
        );
    }
}

export default TableData;