import React, { Component } from 'react';
import CanvasJSReact from '../assets/canvasjs/canvasjs.react';
import env from "react-dotenv";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

 
class LineMultiples extends Component {
	constructor() {
		super();
		this.toggleDataSeries = this.toggleDataSeries.bind(this);
		this.state = {
			posts: []
		};
	}
	toggleDataSeries(e){
		if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
		}
		else{
			e.dataSeries.visible = true;
		}
		this.chart.render();
	}

	componentDidMount() {
		this.fetchTrans();
	}

	componentDidUpdate(prevProps) {
		if((prevProps.selectedCountry !== this.props.selectedCountry) || (prevProps.selectedPeriod !== this.props.selectedPeriod)){
			this.fetchTrans();
		}
	}

	fetchTrans(){
		fetch(env.API_URL+'/api/'+this.props.gate+'/line/'+this.props.selectedPeriod)
			.then((response) => response.json())
			.then((data) => {
				this.setState({posts:data});
			})
			.catch((err) => {
				console.log("LINEMULTIPLE : TRANSACTION");
				console.log(err);
		});
	}

	render() {
		const options = {
				animationEnabled: true,
				title:{
					text: "Overview Of all transactions"
				},
				axisY : {
					title: (this.props.gate === 'user') ? "Nbre" : "Cash(CAD)",
					includeZero: false
				},
				toolTip: {
					shared: true
				},
				legend: {
					verticalAlign: "center",
					horizontalAlign: "right",
					reversed: true,
					cursor: "pointer",
					itemclick: this.toggleDataSeries
				},
			data:this.state.posts
			}
		
		return (
			<div className="topSpace padingSpace">
				<CanvasJSChart options = {options}  onRef={ref => this.chart = ref} />
			</div>
		);
	}
}

export default LineMultiples;