import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Transactions from '../pages/subPages/Transactions';
import Abonnement from '../pages/subPages/Abonnement';
import User from '../pages/subPages/User';

function Navigation() {

	return (
		<Tabs
			defaultActiveKey="transaction"
			id="afb-dashbord-tab"
			className="mb-3"
			fill
		>
			<Tab eventKey="transaction" title="TRANSACTIONS">
				<Transactions />
			</Tab>
			<Tab eventKey="users" title="USERS">
				<User />
			</Tab>
			<Tab eventKey="abonnements" title="ABONNEMENTS">
				<Abonnement />
			</Tab>
		</Tabs>
	);
}

export default Navigation;