import React, { Component } from 'react';

class Error extends Component {

    render() {
        return (
            <>
                <h1>404</h1>
                <div className="error_info">
                    <h2>We can't find that page</h2>
                    <p>We're fairly sure that page used to be here, but seems to have gone missing. We do apologise on it's
                        behalf.</p><a href="/" target="_blank" rel="noreferrer noopener">Home</a>
                </div>
            </>
        );
    }
}

export default Error;


